@use '@angular/material' as mat;

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-basepalette: (
  100 : #bec8cb,
  400 : #000c,
  700 : #1c3742,
  contrast: (
    100 : #000000,
    400 : #ffffff,
    700 : #ffffff,
  )
);

$md-warnpalette: (
  100 : #f8d4cb,
  700 : #e05c40,
  contrast: (
    100 : #000000,
    700 : #000000,
  )
);

$md-accentgpalette: (
  700 : #e3b657,
  800 : #dfaf4d,
  900 : #d9a23c,
  contrast: (
    700 : #000000,
    800 : #000000,
    900 : #000000,
  )
);


$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($md-basepalette, 400),
    accent: mat.define-palette($md-accentgpalette, 700, 800, 900),
    warn: mat.define-palette($md-warnpalette, 700)
  )
));
