@use '@angular/material' as mat;
@import './themes';

@import 'codemirror/lib/codemirror';

@include mat.all-component-themes($light-theme);

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error-report-box {
  position: fixed;
  z-index: 999999999;
  //top: 10px;
  //left: 20px;
  //width: 100%;
  //height: 100%;
  //background-color: rgba(255, 255, 255, 0.5);
  padding-left: 85px;
  padding-top: 40px;
  color: #002222;
  font-size: 15px;
  display: flex;
  flex-flow: column;
}

.error-report-box li {
  list-style-type: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-col-left {
  width: 49%;
}

.flex-col-right {
  width: 49%;
}

@media screen and (max-width: 950px) {
  body {
    .flex-col-left {
      margin: 0;
    }

    .flex-col-right {
      margin: 0;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .flex-col-left {
    width: 100%;
  }

  .flex-col-right {
    width: 100%;
  }
}

body, html {
  font-family: sans-serif;
}

#toast-container > div {
  width: 500px;
}

.spacer {
  flex: 1 1 auto;
}

.form {
  margin-top: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

.form > * {
  width: 100%;
}

.form-row {
  white-space: nowrap;
  display: flex;
}

mat-toolbar.filter {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  z-index: 2;

  h1, h2, h3, h4 {
    margin-right: 2em;
  }

  mat-form-field {
    margin-right: 1em;
  }
}

.mat-toolbar .mat-mdc-button {
  margin: 0 5px;
}

.full-width {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-30 {
  width: 30%;
}

.width-60 {
  width: 60%;
}

.width-80 {
  width: 80%;
}

.width-90 {
  width: 90%;
}

a {
  color: black;
  text-decoration: none;
}

.mat-mdc-button:hover {
  text-decoration: none;
}

.mat-mdc-button.squa-button {
  min-width: 6%;
  height: 52px;
  margin: 0 2px;
}

.items-center {
  text-align: center;
}


/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-content.center-form {
  max-width: 660px;
  display: flex;
  flex-direction: column;
  margin: 1em auto;
}

.elem-left {
  padding-right: 10px;
}

.elem-right {
  padding-left: 10px;
}

.small-Icon {
  font-size: 20px;
}

.success-Icon {
  color: green;
}

.danger-Icon {
  color: #a94442;
}

.warning-Icon {
  color: orange;
}

.text-muted {
  color: #999 !important;
}

.remark-muted {
  color: #cccccc;
}

.line-through {
  text-decoration: line-through;
  color: #777;
}

.mat-column-edit {
  justify-content: flex-end;
}

.italic {
  @extend .text-muted;
  font-style: italic;
}

.text-warning {
  color: #8a6d3b;
}

.text-red {
  color: #a94442;
}

.mat-mdc-row:hover {
  background-color: #EEEEEE;
}

.label-button {
  font-size: 12px;
  padding: 0 5px;
  line-height: 26px;
  word-wrap: break-word;
}

.bold-text {
  font-weight: bold;
}

.ng-draggable {
  cursor: move;
}

.cdk-drag-preview {
  background-color: whitesmoke;
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
  border: none;
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// TODO fix this!
.mat-datepicker-content {
  background-color: #eee;
  border: 1px solid black;
}
